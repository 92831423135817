import React, { useContext } from "react";
import { AccordionContext } from "/components/shared/accordion/accordion";

// This component pairs with accordion-panel inside of AccordionContext
// 'single' from context limits only one open at a time
const AccordionHeader = ({ children, key }) => {
  const { openAccordion, setOpenAccordion, single } = useContext(AccordionContext);

  const handleAccordion = (e) => {
    const panel = e.target.nextElementSibling;

    // user clicked on currently open accordion
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      e.target.classList.toggle("accordion-active");
    } else {
      // user clicked on unopened accordion
      panel.style.maxHeight = panel.scrollHeight + "px";
      e.target.classList.toggle("accordion-active");
    }

    // if single set to true and opened accordion, close opened accordion
    if (single && openAccordion) {
      // null if closing currently open panel
      if (!panel.style.maxHeight) {
        setOpenAccordion(undefined);
      } else {
        openAccordion.style.maxHeight = null;
        openAccordion.previousElementSibling.classList.toggle("accordion-active");
        setOpenAccordion(panel);
      }
    }

    // if single set to true and none opened yet
    if (single && !openAccordion) {
      setOpenAccordion(panel);
    }
  };

  return (
    <div className="list__item accordion" onClick={(e) => handleAccordion(e)} key={key}>
      {children}
    </div>
  );
};

export default AccordionHeader;
