import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";
import { IssuesToolTip } from "/components/shared/IssuesTooltip";
import _ from "lodash";
import ProgressBar from "../../../widgets/progress_bar";

function InvoiceLinesTable({
  data,
  isLoading,
  searchQuery,
  setSearchQuery,
  setNotice,
  handleRowClick,
  selectedRowIndex,
}) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("invoice_number", {
        header: "Invoice Number",
        sorting: false,
        cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
      }),
      columnHelper.accessor("submission_date", {
        header: "Submission Date",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("invoice_date", {
        header: "Invoice Date",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("ndc", {
        header: "NDC",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("id_340b", {
        header: "340b ID",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("service_provider", {
        header: "Ship-To",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("package_quantity", {
        header: "Verified Qty",
        cell: (info) => {
          return (
            <span>
              {info.row.original.matched_packages} of {info.row.original.package_quantity}
            </span>
          );
        },
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("filled_percentage", {
        header: "Status",
        cell: (info) => statusBar(info),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor((row) => row.issues, {
        header: "Issues",
        enableSorting: false,
        cell: ({ row, getValue }) => {
          if (_.size(getValue()) > 0) {
            return <IssuesToolTip row={row} getValue={getValue} />;
          }
          return "--";
        },
      }),
    ];
  });

  const statusBar = (info) => {
    const label = (
      <div className="flex flex--space-between container--fw t--tny">
        <span>Completed</span>
        <span>
          {info.row.original.filled_packages} of {info.row.original.package_quantity}
        </span>
      </div>
    );

    return (
      <span style={{ display: "block", width: "140px" }}>
        {info.row.original.filled_packages <= 0 ? (
          <div className="badge">
            <div className={`badge__indicator badge__indicator--blue`} />
            Pending
          </div>
        ) : (
          <ProgressBar
            progress={info.row.original.filled_packages}
            total={info.row.original.package_quantity}
            label={label}
            height="8px"
          />
        )}
      </span>
    );
  };

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default InvoiceLinesTable;
