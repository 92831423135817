import React from "react";

// This component pairs with accordion-header inside of AccordionContext
const AccordionPanel = ({ children }) => {
  return (
    <>
      <div className="accordion-panel">{children}</div>
    </>
  );
}

export default AccordionPanel;
