import React from "react";
import InvoiceLinesTable from "./invoice_lines_table";

const PurchasesTable = ({invoice, setSelectedLineItem, isLoading}) => {
  return (
    <>
      <InvoiceLinesTable
        data={invoice}
        isLoading={isLoading}
        handleRowClick={(event) => setSelectedLineItem(event.original)}
        getIndicatorColor={getIndicatorColor}
      />
    </>
  );
};

const getIndicatorColor = (status) => {
  switch (status?.toLowerCase()) {
    case "fully matched":
      return "green";
    case "partially matched":
      return "blue";
    case "not matched":
      return "grey";
    default:
      return "blue";
  }
};

export default PurchasesTable;