import React, { useEffect, useState, useRef } from "react";
import { Accordion } from "/components/shared/accordion/accordion";
import AccordionHeader from "/components/shared/accordion/accordion_header";
import AccordionPanel from "/components/shared/accordion/accordion_panel";
import BankAccountStatus from "/components/entities/components/BankAccountStatus";
import BankAccountNotice from "/components/entities/components/BankAccountNotice";

const BankAccountList = ({ entity_details }) => {
  const renderAccountNumber = (acc_number) => {
    if (acc_number) {
      return `**** **** **** ${acc_number}`;
    }

    return "Pending";
  };

  const renderButton = (id) => {
    return (
      <a href={`/entities/${id}`}>
        <div className="button button--sm button--outlined">View Entity</div>
      </a>
    );
  };

  return (
    <div className="bank_details__container">
      <Accordion single={true}>
        {entity_details.map((detail) => {
          return (
            <div key={detail.id_340b}>
              <AccordionHeader>
                <div className="t__stack">
                  <div className="t__stack__subtitle">Entity ID</div>
                  <div className="t__stack__title t--sm">{detail.id_340b}</div>
                </div>
                <div className="t__stack">
                  <div className="t__stack__subtitle t--right">status</div>
                  <BankAccountStatus status={detail.status} />
                </div>
              </AccordionHeader>
              <AccordionPanel>
                <BankAccountNotice status={detail.status} />
                <div className="bank_details__card">
                  <div className="bank_details__card__header">{renderButton(detail.id_340b)}</div>
                  <div className="bank_details__card__value">$0.00</div>
                  <div className="bank_details__card__footer">
                    <div className="t__stack">
                      <div className="t__stack__subtitle t--white">Account</div>
                      <div className="t__stack__title t--sm t--white">{renderAccountNumber(detail.acc_number)}</div>
                    </div>
                    <div className="t__stack">
                      <div className="t__stack__subtitle t--white t--right">Entity</div>
                      <div className="t__stack__title t--sm t--white">{detail.id_340b}</div>
                    </div>
                  </div>
                </div>
              </AccordionPanel>
            </div>
          );
        })}
      </Accordion>
    </div>
  );
};

export default BankAccountList;
