import React, { useState, useEffect } from "react";
import PackagesTable from "./tables/packages_table";
import { Illustration } from "/components/images/svgs";
import QuantityModal from "/components/modals/quantity_modal";
import _ from "lodash";

const PurchaseDetails = ({ line, reverse }) => {
  const [open, setOpen] = useState(false);

  if (!line) {
    return (
      <>
        <div className="section">Details</div>
        <div
          className="flex justify-center flex-col"
          style={{ height: "calc(100vh - 340px)" }}
        >
          <Illustration width={178} />
          <div className="t--sm t--upcase t--center mt--8 mb--8">
            No Purchase Selected
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="section">Details</div>
      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">Invoice Number</div>
        <div className="t--sm">{line.invoice_number}</div>
      </div>

      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">Invoice Date</div>
        <div className="t--sm">{line.invoice_date}</div>
      </div>

      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">Submission Date</div>
        <div className="t--sm">{line.submission_date}</div>
      </div>

      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">NDC</div>
        <div className="t--sm">{line.ndc}</div>
      </div>

      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">Ship-To</div>
        <div className="t--sm">{line.service_provider}</div>
      </div>

      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">Wholesaler</div>
        <div className="t--sm">{line.wholesaler}</div>
      </div>

      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">Filled</div>
        <div className="t__stack">
          <div className="t--sm t--right">{`${line.filled_packages} of ${line.package_quantity}`}</div>
          <div className="t--sm t--light t--right">
            {line.filled_percentage}
          </div>
        </div>
      </div>

      <div className="list__item list__item--disable-hover gap--8">
        <div className="t--subtitle t--sm">Quantity</div>
        <div className="flex container--end item-center">
          <div className="t--sm t--center" style={{ "margin-right": "10px" }}>
            {line.package_quantity}
          </div>
          <div
            className="button button--outlined button--sm"
            onClick={() => setOpen(true)}
          >
            <div
              className="material-symbols-outlined mg--0"
              style={{ margin: 0 }}
            >
              edit
            </div>
          </div>
        </div>
      </div>

      <div className="list__item list__item--disable-hover">
        <div className="t--subtitle t--sm">Status</div>
        <div className="t--sm">{line.status}</div>
      </div>

      <div className="section">Issues</div>
      {renderIssues(line.issues)}
      <QuantityModal
        title="Update Package Quantity"
        purchase={line}
        open={open}
        setOpen={setOpen}
        action={reverse}
      />
      {renderPackagesTable(line)}
    </>
  );
};

const renderPackagesTable = (line) => {
  return (
    <>
      <div className="section">Packages</div>
      <PackagesTable data={line.packages} />
    </>
  );
};

const renderIssues = (issues) => {
  if (_.isEmpty(issues)) {
    return (
      <div className="list__item list__item--disable-hover">
        <div className="t--sm t--italic t--subtitle">No issues found</div>
      </div>
    );
  }
  return issues?.map((issue, index) => (
    <div className="list__item list__item--disable-hover">
      <div className="container container--align-center">
        <div className="indicator indicator--red"></div>
        <div className="t--subtitle t--sm">Issue</div>
      </div>
      <div className="t--sm">{issue.label}</div>
    </div>
  ));
};

export default PurchaseDetails;
