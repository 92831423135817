import React from "react";
import _ from "lodash";
import shapes from "/images/shapes.svg";
import logo from "/images/logo_light.png";

const ConfirmationModal = ({ title, subtitle, body, open, setOpen, action  }) => {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    action();
    setOpen(false);
  };

  const renderHeader = () => {
    return (
      <>
        <div className="modal__header">
          <div className="t__stack">
            <div className="t__stack__title t--lg">{title}</div>
            {subtitle && (
              <div className="t__stack__subtitle">{subtitle}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body" style={{minHeight: 100}}>
        <p>
          {body}
        </p>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer flex--space-between">
        <button className="button button--outlined" onClick={() => handleCancel()}>
          Cancel
        </button>
        <button className="button" onClick={() => handleConfirm()}>
          Confirm
        </button>
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onClick={(e) => {
            e.target.className == "modal__background" && handleDismiss();
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
      );
    }
  };

  return <>{renderModal()}</>;
};

export default ConfirmationModal;
