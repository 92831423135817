import React, { useState, createContext } from "react";

export const AccordionContext = createContext(null);

// Must use with accordion header and accordion panel in render order
//  <Accordion>
//    <AccordionHeader> Header content <AccordionHeader/>
//    <AccordionPanel> Panel content <AccordionPanel />
//  <Accordion/>

// 'single' prop determines whether only one accordion can be opened at a time
export const Accordion = ({ children, single }) => {
  const [openAccordion, setOpenAccordion] = useState(undefined);

  return (
    <AccordionContext.Provider value={{ openAccordion, setOpenAccordion, single }}>
      {children}
    </AccordionContext.Provider>
  );
};
