import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import EntitySelect from "../components/entity_select";
import axios from "axios";

import RegistrationForm from "../forms/registration_form";
import FormCheckbox from "~/components/shared/FormCheckbox";
import Notice from "~/components/shared/Notice";
import SubmitButton from "~/components/shared/SubmitButton";

const RegisterEntity = ({ entities }) => {
  const [open, setOpen] = useState(false);
  const [apiError, setApiError] = useState("");
  const existing340bIds = entities.map((e) => e.value);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notice, setNotice] = useState({
    kind: "success",
    open: false,
    message: null,
  });

  const defaultValues = {
    registration: {
      ein: "",
      id_340b: "",
      ein_letter: null,
      article_of_incorporation: null,
    },
  };

  const {
    control,
    watch,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ defaultValues, mode: "onChange" });

  const watchedId340B = watch("registration.id_340b");

  const handleValidSubmit = (value) => {
    setIsSubmitting(true);
    axios
      .post("/entities/registration_details", value, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
      })
      .then(() => {
        reset(defaultValues);
        setOpen(false);
        setIsSubmitting(false);
        setNotice({
          kind: "success",
          open: true,
          message: "Success! Please allow up to 5 business days for registration review and processing.",
        });
        setApiError("");
      })
      .catch((err) => {
        setIsSubmitting(false);
        setApiError(err.response?.data?.message || err.message);
      });
  };

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Add a Covered Entity</div>
          <div className="t__stack__subtitle">
            Complete the form below and upload the required documentation to add a covered entity to your profile.
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    if (watchedId340B) {
      return <RegistrationForm selectedEntity={watchedId340B} control={control} errors={errors} />;
    }
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <div className="form__group">
          <label className="form__label">Select Your Covered Entity</label>
          <Controller
            control={control}
            name="registration.id_340b"
            rules={{
              required: "Field is required",
            }}
            render={({ field: { onChange, value, ref } }) => (
              <EntitySelect value={value} inputRef={ref} onChange={onChange} filterList={existing340bIds} />
            )}
          />
          {errors.registration?.id_340b && (
            <span className="form__error--message">{errors.registration.id_340b.message}</span>
          )}
        </div>
        <p className="form__group__note">
          By checking the box below you confirm your authorization to register an account with Beacon for the covered
          entity(s) selected above.
        </p>
        <FormCheckbox
          name="registration.signatory"
          label="I am authorized"
          control={control}
          required={true}
          errors={errors}
        />
        <p className="form__group__note">
          <span>By checking the box below you confirm your understanding of and agree to the </span>
          <span className="t--link">
            <a href="/pages/terms" target="_blank">
              Terms of Use.
            </a>
          </span>
        </p>
        <FormCheckbox
          name="registration.terms"
          label="Accept Terms & Privacy Policy"
          control={control}
          required={true}
          errors={errors}
        />
        {renderForm()}
        {apiError && <div className="form__error--message">{apiError}</div>}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button className="button button--label" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <SubmitButton className="button" isDisabled={!isValid || isSubmitting} />
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onClick={(e) => {
            e.target.className === "modal__background" && setOpen(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            <form method="post" onSubmit={handleSubmit(handleValidSubmit)}>
              {renderHeader()}
              {renderBody()}
              {renderFooter()}
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="menu__item" style={{ "--content": "'Add Entity'" }} onClick={() => setOpen(!open)}>
        <span className="material-symbols-outlined">add_home_work</span>
      </div>
      {renderModal()}
      <Notice details={notice} />
    </>
  );
};

export default RegisterEntity;
