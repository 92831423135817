import React, { useState, useEffect } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

const Theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

export const HistorySkeleton = ({rows}) => {
  return [...Array(rows)].map((_, i) => (
    <ThemeProvider theme={Theme}>
      <Timeline style={{ padding: 0, width: "296px" }} className="animated delay-2 fadeIn">
        <TimelineItem>
          <TimelineOppositeContent className={history.not_considered ? "t--grey" : ""}>
            <Skeleton animation="wave" sx={{fontSize: 12, width: "100%", textAlign: 'left'}}></Skeleton>
            <Skeleton animation="wave" sx={{fontSize: 12, width: "60%", textAlign: 'left'}}></Skeleton>
            <Skeleton animation="wave" sx={{fontSize: 12, width: "100%", textAlign: 'left'}}></Skeleton>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={`timeline timeline--not-considered`} />
            {i == rows -1? (
              ""
            ) : (
              <TimelineConnector
              className={`timeline timeline--not-considered`}
              />
            )}
          </TimelineSeparator>
          <TimelineContent  className="t--grey">
            <div className="badge badge--disabled mb-1">
              <div className="badge__indicator badge__indicator--grey" />
              <Skeleton animation="wave" sx={{fontSize: 12, width: "80px"}}></Skeleton>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </ThemeProvider>
  ));
};
