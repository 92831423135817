import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Input from "/components/shared/Input";
import Tabs from "/components/shared/tabs";
import * as XLSX from "xlsx";

const SheetJSFT = ["xlsx", "xls", "csv"];

const IdSearchModal = ({ setOpen, submit }) => {
  const [value, setValue] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    submit(value);
  };

  const setSearchValue = (val) => {
    var valueArray = _.split(val, "\n");

    valueArray = _.map(valueArray, function (v) {
      return _.trim(v).replace("CLM-", "").replace("PUR-", "").replace("PKG-", "").replace("CR-", ""); // Remove CLM-, PUR-, PKG-, CR- prefix
    });

    setValue(
      _.filter(valueArray, function (v) {
        return v != "";
      })
    );
  };

  const handleChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  useEffect(() => {
    if (attachment) {
      processFile(attachment).then((data) => {
        var firstColumn = _.map(data, function (v) {
          return v[0];
        });
        setSearchValue(firstColumn.join("\n"));
      });
    }
  }, [attachment]);

  const tabs = [
    { id: 1, label: "Paste IDs" },
    { id: 2, label: "Upload XLSX/CSV" },
  ];

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 1:
        return (
          <>
            <Input name="id-search" label="Beacon IDs" setValue={setSearchValue} multiline minRows={3} maxRows={10} />
            <div className="t--sm">Enter your Beacon IDs, each on a new line</div>
          </>
        );
      case 2:
        return (
          <>
            <div class="form__group">
              <div className="form__label">Attach File with Beacon IDs</div>
              {renderFileSelect()}
            </div>
            <div className="t--sm">Upload a XLSX or CSV with your Beacon IDs, 1 per row in the first column of the file</div>
          </>
        );
      default:
        return <div className="t--sm">Select a tab</div>;
    }
  };

  const renderFileSelect = () => {
    if (attachment) {
      return (
        <div className="file_upload">
          <div className="file_upload__icon">
            <span class="material-symbols-outlined">description</span>
          </div>
          <div className="file_upload__details">
            <div className="file_upload__details__title">{attachment.name}</div>
            <div className="file_upload__details__subtitle">{attachment.size} bytes</div>
          </div>
        </div>
      );
    }

    return (
      <div className="container--fw">
        <div className="file_upload">
          <div class="file_upload__note">Please select a file to upload</div>
          <div
            className="button button--outlined"
            onClick={() => {
              document.getElementById("id-attachement").click();
            }}
          >
            Attach
          </div>
        </div>
        <input
          type="file"
          style={{ display: "none" }}
          className="form-control"
          id="id-attachement"
          accept={SheetJSFT}
          onChange={handleChange}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className="modal__header">
          <div className="t__stack">
            <div className="t__stack__title t--lg">Search By Beacon ID</div>
          </div>
        </div>
      </>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {renderSelectedTab()}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer flex--space-between">
        <button className="button button--outlined" onClick={() => handleCancel()}>
          Cancel
        </button>
        <button className="button" onClick={() => handleSubmit()}>
          Submit
        </button>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <div
        className="modal__background"
        onClick={(e) => {
          e.target.className == "modal__background" && handleCancel();
        }}
      >
        <div className="modal__container animated fadeInUp--sm" style={{ width: 750 }}>
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    );
  };

  return <>{renderModal()}</>;
};

const processFile = async (file) => {
  const ab = await file.arrayBuffer();
  const wb = XLSX.read(ab);
  const ws = wb.Sheets[wb.SheetNames[0]];

  var data = XLSX.utils.sheet_to_json(ws, { header: 1 });

  return data;
};

export default IdSearchModal;
